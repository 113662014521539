.parallax {
    position: relative;
    padding: 10rem 0 10rem;
    text-align: center;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    @include media-breakpoint-up(md) {
        padding: 15rem 0 15rem;
        background-attachment: fixed;
    }
}

// Apple doesn't get parallax because of the viewport bug with
// background-attachment: fixed
.no-parallax .parallax {
    background-attachment: scroll !important;
}

.slogan {
    color: white;

    .parallax-content {
        padding: 3rem 0;
        background: rgba(0, 0, 0, 0.7);
    }
}

#slogan1 {
    @include responsive-background-image("../images/kelly-sikkema-475112-unsplash");
}

#slogan2 {
    @include responsive-background-image("../images/StockSnap_N1SXSN6HUQ");
}

#slogan3 {
    @include responsive-background-image("../images/desk-discussing-discussion-1311518");
}

/**
 * Parallax Fixes
 */

// FIX I
// https://stackoverflow.com/questions/24154666/background-size-cover-not-working-on-ios
// Not really parallax on mobile anymore...
// .parallax {
//     background-attachment: scroll;

//     @include media-breakpoint-up(md) {
//         background-attachment: fixed;
//     }
// }

// FIX II
// https://stackoverflow.com/a/20591646
// .parallax {
//     -webkit-background-size: cover;
// }

// FIX III
// different CSS parallax approach
// Need to edit index.html, navigation.js, scroll-reveal-init.js, disable smooth-scrolling.js 
// (adjust jump links with absolutely positioned anchors offset by -{header-height} px).
// #slogan1 {
//     background-image: none;
//     z-index: -3;
// }
// #slogan2 {
//     background-image: none;
//     z-index: -2;
// }
// #slogan3 {
//     background-image: none;
//     z-index: -1;
// }
// // quickfix to have higher specificity
// body {
//     overflow: hidden !important;
// }
// body .site .site-content {
//     perspective: 1px;
//     transform-style: preserve-3d;
//     overflow-x: hidden;
//     overflow-y: auto;
//     height: 100vh;

//     .parallax {
//         display: flex;
//         flex: 1 0 auto;
//         position: relative;
//         height: 100vh;
//         padding: 0;
//         transform: translateZ(-1px) scale(2);
//         z-index: -1;
//         left: -50%;
//         top: 0;
//         width: 200%;
//         height: 200%;

//         img {
//             position: absolute;
//             top: 0;
//             left: 0;
//             right: 0;
//             bottom: 0;
//             margin: auto;
//             min-width: 50%;
//             min-height: 50%;
//             max-width: none;
//         }
//     }

//     .content-section {
//         display: block;
//         position: relative;
//         background: rgb(238, 255, 249);
//         transform: translateZ(0);
//         z-index: 1;
//     }
// }

/**
 * End Parallax Fixes
 */
