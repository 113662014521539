.navbar {
    font-family: $font__body;
    background: get-color($key: 'secondary');
    color: #fff;
    transition: background .2s ease-in-out, box-shadow .3s ease-in-out;
    box-shadow: $header__box-shadow;

    @include media-breakpoint-up(md) {
        padding: 0 15px;
    }
    
    img {
        max-height: 35px;

        @include media-breakpoint-up(md) {
            max-height: 45px;
        }
    }
}

.home .navbar {
    @include media-breakpoint-up(md) {
        &:not(.shrink) {
            background: transparent;
            box-shadow: none;
            transition: background .2s ease-in-out .1s, box-shadow .2s ease-in-out;

            .navbar-brand img {
                max-height: 80px;
            }
        }
    }
}

.navbar-nav {
    @include media-breakpoint-up(md) {
        justify-content: flex-end;
        align-items: flex-end;

        .home & {
            width: 100%;
        }
    }

    .nav-link {
        position: relative;
        padding: 1.5rem .5rem;
        // font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
    }

    .nav-item:last-of-type .nav-link {
        @include media-breakpoint-up(md) {
            padding-right: 0;
        }
    }
}

.navbar-brand {
    position: relative;
    margin-right: .5rem;
    text-transform: uppercase;

    span {
        padding: 5px .7rem 6px;
        border: 2px solid $white;
        border-radius: 3px;
    }

    img {
        transition: max-height .3s ease-in-out;
    }
}

.navbar, .navbar > .container,
.navbar > .container-fluid {
    // align-items: flex-end;
}

.navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    @include media-breakpoint-up(sm) {    
        padding-left: 15px;
        padding-right: 15px;
    }
}

.navbar-contact {
    @include media-breakpoint-down(xs) {
        display: none;
    }

    @include media-breakpoint-up(md) {
        order: 1;
        padding: 1rem 0 1rem 1rem;
    }
}
