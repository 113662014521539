.form-control:not(textarea) {
    border-radius: 30px;
}

.input-group-text {
    padding: 0.375rem 0.5rem 0.375rem 1rem;
    border-radius: 30px;

    .svg-icon {
        width: 17px;
        height: 17px;
    }
}

.info {
    &__icon {
        float: left;
        margin-top: 5px;
        margin-right: .5em;
    }

    &__description {
        overflow: hidden;
    }

    .svg-icon {
        width: 1em;
        height: 1em;
        fill: white;
        vertical-align: text-top;
    }
}

.contact {
    position: relative;
    color: white;
    @include responsive-background-image('../images/contact2');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background: rgba(0,0,0,.8);
    }

    a {
        color: inherit;
        border-bottom: 2px solid theme-color('primary');

        &:hover,
        &:focus {
            border-bottom: 4px solid theme-color('primary');
            text-decoration: none;
        }
    }

    .contact-content {
        position: relative;
        z-index: 1;
    }

    .card {
        background: transparent;
        border: 0;
    }

    .card-header {
        background-color: inherit;
        border-bottom: 0;
    }

    .card-body {
        padding: 0;
    }

    textarea.form-control {
        @include media-breakpoint-up(lg) {
            height: 394px;
        }
    }

    .form-group:nth-child(3) > label:first-child,
    .form-group:nth-child(3) > input[type="text"] {
        position:absolute !important;
        left:-999999px;
    }
}

button:disabled {
    cursor: not-allowed;
}

#contact-form-submit {
    margin-left: .5rem;
}

// Timer Styles 
// https://www.cssscript.com/circular-countdown-timer-javascript-css3/

#timer-info {
    margin-top: .5rem;
}

.circle {
    position: relative;
    display: inline-block;
    margin-bottom: .5rem;
    transition: opacity .3s ease-out, visibility .3s ease-out;
}

.display-remaining-time {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.e-c-base {
    fill: none;
    stroke: #B6B6B6;
    stroke-width: 4px
}

.e-c-progress {
    fill: none;
    stroke: get-color($key: 'primary');
    stroke-width: 4px;
}

.e-c-pointer {
    fill: #FFF;
    stroke: get-color($key: 'primary');
    stroke-width: 2px;
}

#e-pointer { 
    transform: rotate(360deg);
}