h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font__headings;
    clear: both;
}

h2 {
    @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
    }
}
