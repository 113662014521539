html,
body,
.site {
    min-height: 100vh;
    height: auto;
}

.site {
    display: flex;
    flex-direction: column;
}

.site-content {
    flex: 1 0 auto;
}
