// Only show the pre-loader styles if Javascript is enabled.
.js {
    // Hide the menu but display the branding on the pre-loader screen.
    body {
        overflow: hidden;
    }

    .bg-primary,
    .bg-primary::after {
        background: transparent !important;
        box-shadow: none;
    }

    .navbar-toggler,
    .navbar-collapse,
    .navbar-contact {
        opacity: 0;
        visibility: hidden;
    }

    // Pre-loader styles.
    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1001;

        &__animation {
            position: relative;
            width: 75px;
            height: 100px;
            opacity: 1;
            z-index: 1002;

            &--bar {
                position: absolute;
                bottom: 0;
                width: 10px;
                height: 50%;
                background: get-color($key: 'secondary');
                transform-origin: center bottom;
                box-shadow: 1px 1px 0 rgba(0, 0, 0, .2);

                @for $i from 1 through 5 {
                    &:nth-child(#{$i}) {
                        left: ($i - 1) * 15px;
                        transform: scale(1, $i*.2);
                        animation: barUp#{$i} 4s infinite;
                    }
                }
            }
            &--ball {
                position: absolute;
                bottom: 10px;
                left: 0;
                width: 10px;
                height: 10px;
                background: get-color($key: 'secondary');
                border-radius: 50%;
                animation: ball 4s infinite;
            }
        }

        &__section {
            position: fixed;
            top: 0;
            width: 51%;
            height: 100%;
            background: map-get($map: $theme-colors, $key: 'primary');
            z-index: 1000;

            &--left {
                left: 0;
            }

            &--right {
                right: 0;
            }
        }
    }

    // Hide the pre-loader when the content is loaded.
    .loaded {
        overflow: auto;

        .bg-primary::after {
            background: linear-gradient(to right, transparent, #fff, transparent) !important;
            transition: background .5s ease-out;

            @include media-breakpoint-up(md) {
                background: linear-gradient(to right, transparent, rgba(255, 255, 255, .7), rgba(255, 255, 255, .3)) !important;
            }
        }

        .bg-primary {
            background: $site-gradient !important;
            box-shadow: $header__box-shadow;
            transition: background .5s ease-out, box-shadow .5s ease-out;
        }
    
        .navbar-toggler,
        .navbar-collapse,
        .navbar-contact {
            opacity: 1;
            visibility: visible;
            transition: opacity .5s ease-out, visibility .5s ease-out;
        }

        .loader {
            visibility: hidden;
            transform: translateY(-100%);
            transition: transform .3s ease-out 1.3s, visibility .3s ease-out 1.3s;

            &__animation {
                opacity: 0;
                transition: opacity .5s ease-out;
            }

            &__section {
                transition: transform .7s cubic-bezier(0.645, 0.045, 0.355, 1.000) .6s;

                &--left {
                    transform: translateX(-100%);
                }

                &--right {
                    transform: translateX(100%);
                }
            }
        }
    }
}

// Make sure the loader is not display without Javascript
.no-js .loader {
    display: none;
}
