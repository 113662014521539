$theme-colors: (
    "primary": #376192,
    "secondary": #cae4db,
    "primary-gradient": #DCAE1D,
    "text-main": #111
);

$navbar-dark-color: #376192;
$navbar-dark-active-color: lighten($navbar-dark-color, 25%);

$site-gradient: linear-gradient(to right, get-color(), get-color($key: 'primary-gradient') 55%, get-color());
