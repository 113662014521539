.banner {
    padding: 15vh 1rem 30vh;
    text-align: center;
    color: #fff;
    @include responsive-background-image('../images/Banner');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;

    h1 {
        font-size: 1.2rem;
        text-shadow: 1px 1px 2px black;
    }

    h3 {
        display: none;
        text-shadow: 1px 1px 2px black;
    }

    @include media-breakpoint-up(xss) {
        h1 {
            font-size: 1.75rem;
        }
    }

    @include media-breakpoint-up(md) {
        padding: 15vh 10rem 40vh;

        h1 {
            font-size: 3.5rem;
        }
        h3 {
            display: block;
        }
    }

    @include media-breakpoint-up(xxl) {
        padding: 15vh 25rem 40vh;
    }


}

.banner-logo img {
    max-height: 200px;

    @include media-breakpoint-up(md) {
        max-height: 340px;
    }
}
