.svg-icon {
    display: inline-block;
    width: 2em;
    height: 2em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    text-align: center;

    @include media-breakpoint-up(md) {
        height: 3em;
        width: 3em;
    }
}

.handshake-icon,
.bullet-icon {
    fill: theme-color('primary');
}

.compass-icon {
    fill: indigo;
}

.lock-icon {
    fill: darkgreen;
}
