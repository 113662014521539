img {
    max-width: 100%;
    height: auto;
}

figure {
    max-width: 100%;

    img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
}

.full-text .about-image {
    @include media-breakpoint-up(lg) {
        margin-top: .8rem;
    }
}
