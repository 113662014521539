.card-active {
    border-top: 2px solid theme-color('primary');

    @include media-breakpoint-up(md) {
        transform: scale(1.07);
    }
}

.card-img-top {
    padding: 1.25rem 1.25rem 0;
}

.card-lg {
    .card-body {
        padding: 3rem 2rem 2rem;
    }

    ul,
    ol {
        li {
            margin-bottom: .7em;

            .svg-icon {
                height: 1em;
                width: 1em;
                margin-right: .3em;
            }
        }
    }
}
