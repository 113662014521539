.footer {
    position: relative;
    padding: 1.25rem 0 3rem;
    font-size: .875rem;
    text-align: center;
    color: $navbar-dark-color;
    background: get-color($key: 'secondary');

    &::before {
        display: block;
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(to right, transparent, #fff, transparent);
    }
}

.site-info {
    a {
        color: inherit;
    }

    span {
        display: block;
        
        @include media-breakpoint-up(md) {
                display: inline;
        }
    }

    @include media-breakpoint-down(sm) {
        .sep {
            display: none;
        }
    }
}
