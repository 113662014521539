.card-deck {
    margin-top: 4rem;
    margin-bottom: 2rem;

    @include media-breakpoint-only(sm) {
        flex-flow: column;
    }

    @supports (grid-area: auto) {
        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .card {
        flex: 0 0 100%;
        margin-bottom: 1rem;
        
        @include media-breakpoint-up(md) {
            flex: 0 0 45%;
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 30%;
        }
    }
}
